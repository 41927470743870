<template>
  <v-card :class="{ 'expand': expand }" class="heatmap-card">
    <v-row class="ma-0">
      <v-col class="pa-0">
        <card-label color="00aeef" content="scan by location"></card-label>
      </v-col>
      <v-col class="pa-0 flex-grow-0">
        <info-tooltip position="top" v-if="!isAvailable('saas:dashboard:map')" content="<a href='#/upgrade'>Upgrade now</a> to access to the advanced map features"></info-tooltip>
        <v-btn v-if="isAvailable('saas:dashboard:map')" @click="resizeMap()" class="card-action-btn" icon>
          <v-icon v-if="expand" color="primary">mdi-close</v-icon>
          <v-icon v-if="!expand" color="primary">mdi-arrow-expand</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div id="mapContainer" class="map"></div>
    <v-row v-show="scanSummary.by_country[orderedCountryByScans[0]] > 0" justify="end" class="ma-0 legend-ctn">
      <span class="px-2">0</span>
      <div class="legend"></div>
      <span class="px-2">{{scanSummary.by_country[orderedCountryByScans[0]]}} scans</span>
    </v-row>
    <div class="map-info-card" v-if="selectedCountry">
      Country: {{selectedCountry.name}}<br>
      Scans: {{selectedCountry.scans}}
    </div>
    <!-- <v-btn @click="expand = !expand">expand</v-btn> -->
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import mapboxgl from 'mapbox-gl'
import CountriesGeoJSON from '@/const/countries.geo.json'
import { mapGetters } from 'vuex'
import InfoTooltip from '@/components/utils/InfoTooltip.vue'

export default {
  name: 'HeatmapCard',
  computed: {
    ...mapGetters(['scanSummary', 'orderedCountryByScans', 'isAvailable']),
    summaryByCountry () {
      return this.scanSummary.by_country
    }
  },
  components: {
    CardLabel,
    InfoTooltip
  },
  data () {
    return {
      map: {},
      expand: false,
      countryGeoScan: CountriesGeoJSON,
      selectedCountry: ''
    }
  },
  methods: {
    resizeMap () {
      this.expand = !this.expand
      if (this.expand) {
        window.scrollTo(0, 0)
      }
      setTimeout(() => {
        this.map.resize()
      }, 500)
    }
  },
  watch: {
    summaryByCountry (newVal) {
      this.countryGeoScan.features.forEach((country) => {
        country.properties.scans = this.scanSummary.by_country[country.properties.iso_a2] ? this.scanSummary.by_country[country.properties.iso_a2] : 0
        country.properties.density = this.orderedCountryByScans.length > 0 ? country.properties.scans / this.scanSummary.by_country[this.orderedCountryByScans[0]] : 0
      })

      this.map.getSource('countries').setData(this.countryGeoScan)
    }
  },
  mounted () {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXJ0aHVyc291dmlyb24iLCJhIjoiaFpuc0xyTSJ9.1kE6y5ph_7lUu5g2Zmmn2Q'

    this.map = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/arthursouviron/ckjv8ldj70ent19lf5fenlf4d',
      // center: [103.811279, 1.345399],
      zoom: 1
    })

    this.countryGeoScan.features.forEach((country) => {
      country.properties.scans = this.scanSummary.by_country[country.properties.iso_a2] ? this.scanSummary.by_country[country.properties.iso_a2] : 0
      country.properties.density = this.orderedCountryByScans.length > 0 ? country.properties.scans / this.scanSummary.by_country[this.orderedCountryByScans[0]] : 0
    })

    this.map.on('load', () => {
      this.map.addSource('countries', {
        type: 'geojson',
        data: this.countryGeoScan
      })

      this.map.addLayer({
        id: 'countries-fills',
        type: 'fill',
        source: 'countries',
        layout: {},
        paint: {
          'fill-color': '#00aeef',
          'fill-opacity': ['get', 'density']
        }
      })

      this.map.on('mousemove', 'countries-fills', (e) => {
        if (e.features.length > 0) {
          this.selectedCountry = e.features[0].properties
        }
      })
    })
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.map-info-card
  padding: 15px
  position: absolute
  top: 50px
  right: 10px
  background: white

.legend-ctn
  background: white
  padding: 5px
  position: absolute
  bottom: 25px
  right: 5px
  min-width: 250px

.card-action-btn
  margin-top: -4px
  margin-right: 4px

.legend
  min-width: 200px
  min-height: 24px
  background-image: linear-gradient(to right, rgba(255,255,255,0), $primary)

.heatmap-card
  z-index: 99
  position: relative
  height: 460px !important
  padding-top: 8px
  transition: .5s
  &.expand
    position: absolute
    top: 0px
    left: 0
    right: 0
    bottom: 0
    height: calc(100vh - 64px) !important

  #mapContainer
    position: absolute
    top: 40px
    left: 0
    right: 0
    bottom: 0
    width: 100%

</style>
