import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadProducts (options) {
    const params = {
      limit: 100,
      offset: 0,
      is_archived: false
    }

    if (options.campaign) {
      params.campaign = options.campaign
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/products/', { params }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createProduct (product) {

  },

  b64toFile (dataURI) {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1])

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { type: mimeString })
    blob.lastModifiedDate = (new Date()).toISOString()
    blob.name = 'file'

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob.name += '.jpg'
        break
      case 'image/png':
        blob.name += '.png'
        break
    }

    return blob
  },

  async updateProduct (product) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      console.log('pp')
      console.log(product)

      if (typeof product.image === 'string' && product.image.indexOf('data:') === 0) {
        const fileObj = this.b64toFile(product.image)
        const extension = fileObj.type.substring(fileObj.type.indexOf('/') + 1)
        formData.append('image', fileObj, `blob.${extension}`)
      }
      formData.append('name', product.name)
      formData.append('sku', product.sku)
      formData.append('client_url', product.client_url)
      if (typeof product.campaign === 'number') {
        formData.append('campaign', product.campaign)
      }
      formData.append('description', product.description)

      axiosInstance.patch(`/products/${product.id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
