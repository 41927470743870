import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import ArrowDown from '@/components/icons/ArrowDown'
import ArrowUp from '@/components/icons/ArrowUp'
import Product from '@/components/icons/Product'
import QrCode from '@/components/icons/QrCode'
import Scan from '@/components/icons/Scan'
import TetrisIcon from '@/components/icons/TetrisIcon'
import Upgrade from '@/components/icons/Upgrade'
import User from '@/components/icons/User'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      'arrow-up': {
        component: ArrowUp
      },
      'arrow-down': {
        component: ArrowDown
      },
      product: {
        component: Product
      },
      'qr-code': {
        component: QrCode
      },
      scan: {
        component: Scan
      },
      tetris: {
        component: TetrisIcon
      },
      user: {
        component: User
      },
      upgrade: {
        component: Upgrade
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#15bbf0',
        secondary: '#15bbf0',
        accent: '#15bbf0'
      }
    }
  }
})
