<template>
  <v-card>
    <v-row class="pa-5 ma-0">
      <v-col class="flex-grow-0 pa-0 ma-0">
        <div class="image-upload-ctn pr-5">
          <div class="d-flex flex-row align-center mb-3" v-if="productImage">
            <img :src="productImage">
          </div>
          <ImageCropper input-key="logo" @image-selected="onImageSelect"></ImageCropper>
        </div>

        <div class="font-weight-light hint pt-1">
          Supported Formats: PNG or JPG<br>Max 2mb
        </div>
      </v-col>
      <v-col class="py-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row>
            <v-col cols="6" class="pt-0">
              <v-text-field v-model="productForm.name" persistent-hint label="Product Name" hint="Will be visible to customers on the product’s landing page." :rules="rules.name" required></v-text-field>
            </v-col>
            <v-col cols="6" class="pt-0">
              <v-text-field v-model="productForm.sku" persistent-hint label="Product SKU" hint="Internal use only, not visible to customers." :rules="rules.sku" required></v-text-field>
            </v-col>
            <v-col cols="11" class="pt-0">
              <v-text-field @keydown="formatUrl" v-model="productForm.client_url" persistent-hint :rules="rules.client_url" hint="The Product URL can be used later for QR code redirection. Each product can have their own URL." label="Product URL"></v-text-field>
            </v-col>
            <!-- <v-col cols="6" class="pt-0">
              <v-select v-model="productForm.campaign" :items="campaigns" item-text="name" item-value="id" label="Campaign"></v-select>
            </v-col> -->
            <v-col v-if="isAvailable('saas:product:description')" cols="12" class="pt-0">
              <v-textarea v-model="productForm.description" counter="500" label="Description" persistent-hint hint="You can optionally make this visible to customers on the landing page." :rules="rules.description" required></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="handleClose()">Close</v-btn>
      <v-btn text color="primary" @click="handleSave()" :loading="savingProduct" >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import ImageCropper from '@/components/utils/ImageCropper'
import { mapGetters, mapMutations } from 'vuex'
import amplitude from 'amplitude-js'

export default {
  name: 'ProductForm',
  props: ['product'],
  data () {
    return {
      valid: false,
      productForm: {},
      rules: {
        name: [
          v => !!v || 'Product name is required'
        ],
        sku: [
          v => !!v || 'SKU is required'
        ],
        client_url: [
          v => (/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(v) || !v.length) || 'URL must be valid.'
        ],
        description: [
          v => (v.length < 500 || !v.length) || 'Description can only contain up to 500 characters'
        ]
      }
    }
  },
  components: {
    ImageCropper
  },
  computed: {
    ...mapGetters(['campaigns', 'savingProduct', 'isAvailable']),
    productImage () {
      return this.productForm.image ? this.productForm.image : this.productForm.brand && this.productForm.brand.image ? this.productForm.brand.image : require('@/assets/product-placeholder.png')
    }
  },
  methods: {
    ...mapMutations({
      setProductUpdateStatus: 'UPDATE_PRODUCT_UPDATE_STATUS'
    }),

    formatUrl (event) {
      const val = event.target.value
      if (val.indexOf('http://') !== 0 && val.indexOf('https://') !== 0) {
        this.productForm.client_url = 'https://'
        event.preventDefault()
      }
    },

    handleClose () {
      this.$emit('on-close-dialog')
    },

    async handleSave () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      if (this.productForm.id) {
        await this.$store.dispatch('updateProduct', { product: this.productForm })
        if (window.Appcues) {
          window.Appcues.track('product-saved')
        }
        amplitude.getInstance().logEvent('product save')
        this.setProductUpdateStatus(true)
        this.handleClose()
      } else {
        await this.$store.dispatch('createProduct', { product: this.productForm })
        amplitude.getInstance().logEvent('product create')
        this.setProductUpdateStatus(true)
        this.handleClose()
      }
    },

    onImageSelect (image) {
      this.productForm.image = image
    }
  },
  created () {
    if (this.product && this.product.id) {
      this.productForm = {
        ...this.product
      }
    }
  }
}

</script>

<style lang="sass" scoped>

.image-upload-ctn
  text-align: center
  img
    width: 200px

.hint
  font-size: 10px
  text-align: center
  margin-right: 20px

</style>
