import * as types from '../mutations'
import AxiosInstance from '@/utils/axios.instances.js'
import AuthService from '@/services/auth'
import Permissions from '@/const/permissions'

const state = {
  me: {},
  scantrustToken: '',
  error: false,
  errorMessage: '',
  companyVision: false
}

const getters = {
  scantrustAuthToken: (state) => state.scantrustToken,
  scantrustAuthError: (state) => state.error,
  scantrustAuthErrorMessage: (state) => state.errorMessage,
  companyVision: (state) => state.companyVision,
  isAdmin: (state) => {
    return state.me.is_staff
  },

  isScantrustUser: (state) => {
    if (state.me) {
      return ~state.me.email.indexOf('@scantrust.com')
    }
    return false
  },

  currentPlan: (state) => {
    if (state.me.plan && state.me.plan.id !== '@not-set') {
      return state.me.plan
    }
    if (state.me.company) {
      return { id: state.me.company.plan }
    }
  },

  isAvailable: (state, getters) => (feature) => {
    // Check against new plan feature. If plan do not exist, fall back to existing permission system.
    //
    if (!state.me.plan || !state.me.company) {
      return false
    }

    if (state.me.plan && state.me.plan.id !== '@not-set') {
      // New Plan Check
      return ((state.me.plan.interface && state.me.plan.interface[feature]) || (getters.isScantrustUser && !getters.companyVision))
    } else {
      // Old check
      if (!Permissions[state.me.company.plan] || (getters.isScantrustUser && !getters.companyVision)) {
        return true
      }
      return Permissions[state.me.company.plan].features[feature] && Permissions[state.me.company.plan].features[feature].is_available
    }
  }
}

const actions = {
  setScantrustAuthToken ({ commit }, token) {
    AxiosInstance.setSTAuthToken(token)
    commit(types.SCANTRUST_AUTH_SUCCESS, token)
  },

  async loadUserProfile ({ commit }) {
    const profile = await AuthService.loadProfile()
    commit(types.SET_USER_PROFILE, profile)
    return profile
  },

  scantrustAuthError ({ commit }, err) {
    commit(types.SCANTRUST_AUTH_ERROR, err)
  }
}

const mutations = {
  [types.SCANTRUST_AUTH_SUCCESS] (state, token) {
    state.scantrustToken = token
  },

  [types.SCANTRUST_AUTH_ERROR] (state, error) {
    if (error & error.status === 401) {
      state.error = true
      if (state.scantrustToken) {
        state.errorMessage = 'Invalid Token, please refresh your browser'
      } else {
        state.errorMessage = ' Authentication Token not provided'
      }
    }
  },

  [types.SET_USER_PROFILE] (state, profile) {
    state.me = profile
  },

  [types.CHANGE_COMPANY_VISION] (state, status) {
    state.companyVision = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
