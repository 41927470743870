export default {
  'epac-connect': {
    name: 'ePac Connect',
    features: {
      // Timeframe Filter
      'dashboard.filters.timeframe': {
        is_available: false
      },

      'dashboard.map': {
        is_available: false
      },

      // Data Download
      'dashboard.download': {
        is_available: false
      }
    }
  },

  'epac-connect-plus': {
    name: 'ePac Connect Plus',
    features: {
      // Timeframe Filter
      'dashboard.filters.timeframe': {
        is_available: true
      },

      // Data Download
      'dashboard.download': {
        is_available: false
      },

      'dashboard.map': {
        is_available: false
      }

    }
  },

  'epac-connect-premium': {
    name: 'ePac Connect Premium',
    features: {
      // Timeframe Filter
      'dashboard.filters.timeframe': {
        is_available: true
      },

      // Data Download
      'dashboard.download': {
        is_available: true
      },

      'dashboard.map': {
        is_available: true
      },

      'product.description': {
        is_available: true
      }
    }
  }
}
