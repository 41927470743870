<template>
  <div>
    <div class="page-title pl-10 pt-5">Campaigns</div>
    <v-row class="ma-0">
      <v-col class="campaign-list flex-grow-0 flex-shrink-0">
        <div v-show="!loadingCampaigns" class="campaign-item px-10 py-2" :class="{ 'selected': selectedCampaign.id == campaign.id }" @click="selectCampaign(campaign)" v-for="campaign in campaigns" :key="campaign.id">
          {{campaign.name}}
          <info-tooltip content="<strong>Products</strong> and their QR codes are organized by <strong>Campaigns</strong>. Campaigns enable you to create settings which apply to all products and codes within them. If you need more campaigns, please contact our sales team."></info-tooltip>
        </div>
        <div v-show="loadingCampaigns" class="campaign-skeleton-ctn">
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
      </v-col>
      <v-col class="product-list flex-grow-1">
        <div class="edit-title">Edit Your Products</div>
        <div class="mb-4">
          Product info input here can be displayed to customers when they scan that product’s QR code.<br>
          These products’ packaging and QR codes have been or are in the process of being printed.
        </div>
        <div class="product-card" v-show="!loadingProducts" v-for="product in products" :key="product.id">
          <v-row class="ma-0">
            <v-col class="product-picture flex-grow-0 pa-0" :style="{'background-image': 'url(' + getProductImage(product) + ')'}"></v-col>
            <v-col class="flex-grow-1 pa-0 pl-3">
              <div class="product-title font-weight-medium">{{product.name}}</div>
              <div class="product-description">SKU: {{product.sku}}</div>
              <div class="product-description">Product URL: {{product.client_url ? product.client_url : 'N/A'}}</div>
            </v-col>
            <v-col class="flex-grow-0">
              <v-btn @click="editProduct(product)" icon>
                <v-icon color="#15bbf0">mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="loadingProducts" class="skeleton-ctn">
          <v-skeleton-loader width="100%" type="avatar, paragraph"></v-skeleton-loader>
        </div>

        <div class="pt-4">
          See something wrong or want to add a product? <span @click="contactSupport" class="link">Contact us!</span>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="productDialog" width="900" :persistent="true">
      <product-form
        v-if="productDialog"
        :product="this.$route.params && this.$route.params.product ? this.$route.params.product : {}"
        :product-id="this.$route.params && this.$route.params.id ? this.$route.params.id : null"
        @on-close-dialog="handleCloseDialog()">
      </product-form>
    </v-dialog>
  </div>
</template>

<script>

import ProductForm from '@/components/forms/Product'
import { mapGetters, mapMutations } from 'vuex'
import InfoTooltip from '@/components/utils/InfoTooltip.vue'

export default {
  name: 'products',
  components: {
    'product-form': ProductForm,
    InfoTooltip
  },
  data () {
    return {
      productDialog: false
    }
  },
  watch: {
    '$route.params' (newVal) {
      if (this.$route.name === 'products.edit') {
        this.productDialog = true
      }
    }
  },
  computed: {
    ...mapGetters(['campaigns', 'products', 'selectedCampaign', 'loadingProducts', 'loadingCampaigns'])
  },
  methods: {
    ...mapMutations({
      setSelectedCampaign: 'SET_SELECTED_CAMPAIGN'
    }),

    contactSupport () {
      parent.postMessage({ type: 'contact' }, '*')
    },

    editProduct (product) {
      this.$router.push({ name: 'products.edit', params: { id: product.id, product: product } })
    },

    handleCloseDialog () {
      this.productDialog = false
      this.$router.push({ name: 'products' })
    },

    getProductImage (product) {
      return product.image ? product.image : product.brand && product.brand.image ? product.brand.image : require('@/assets/product-placeholder.png')
    },

    selectCampaign (campaign) {
      this.setSelectedCampaign(campaign)
      this.$store.dispatch('loadProducts', { campaign: campaign.id })
      this.$store.dispatch('loadSelectedCampaignConsumerOptions', campaign.id)
    }
  },
  async created () {
    if (!this.campaigns.length) {
      const campaign = await this.$store.dispatch('loadCampaigns')
      this.selectCampaign(campaign)
    } else {
      this.selectCampaign(this.campaigns[0])
    }

    if (this.$route.name === 'products.edit') {
      this.productDialog = true
    }
  }
}

</script>

<style lang="sass">

@import "@/variables.sass"

.link
  color: $primary
  text-decoration: underline

.campaign-item
  cursor: pointer
  &.selected
    background-color: rgba($primary, 0.3) !important

.edit-title
  color: #333333
  font-weight: bold
  font-size: 17px

.product-picture
  min-width: 100px
  min-height: 100px
  background-size: cover

.product-title
  font-size: 16px
  padding-top: 5px
  letter-spacing: 0.15px
  line-height: 1.5
  color: rgba($black, 0.87)

.product-description
  font-size: 14px
  color: rgba($black, 0.6)

.product-list
  padding-top: 14px
  padding-left: 25px

.product-card
  margin-bottom: 2px
  max-width: 800px
  background: white

.campaign-skeleton-ctn
  .v-skeleton-loader__list-item
    width: 100%
    background-color: rgba($primary, 0.3) !important
    border-radius: 0px

.campaign-list
  padding: 0px
  border-right: 1px solid rgba($black, 0.3)
  min-width: 300px
  padding-top: 15px

.v-skeleton-loader
  display: flex
  max-width: 800px

.v-skeleton-loader__paragraph
  background: white
  .v-skeleton-loader__text
    max-width: 100% !important
    &:nth-child(1)
      width: 20%
      height: 16px
      margin-bottom: 20px
    &:nth-child(2)
      width: 80%
    &:nth-child(3)
      width: 60%

.v-skeleton-loader__avatar
  width: 100px !important
  height: 100px !important
  border-radius: 0% !important

.v-skeleton-loader__paragraph
  padding: 10px

</style>
