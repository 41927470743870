import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import campaigns from './modules/campaigns'
import dashboard from './modules/dashboard'
import landingPages from './modules/landing-pages'
import products from './modules/products'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    dashboard,
    campaigns,
    landingPages,
    products
  }
})
