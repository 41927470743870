import * as types from '../mutations'
import ProductService from '@/services/products'
import CampaignService from '@/services/campaigns'

const state = {
  products: [],
  selectedCampaign: {},
  loading: true,
  saving: false,
  consumerOptions: {}
}

const getters = {
  products: (state) => state.products,
  selectedCampaign: (state) => state.selectedCampaign,
  loadingProducts: (state) => state.loading,
  savingProduct: (state) => state.saving,
  consumerOptions: (state) => state.consumerOptions
}

const actions = {

  async loadProducts ({ commit }, { campaign }) {
    commit(types.SET_PRODUCT_LOADING, true)
    const products = await ProductService.loadProducts({ campaign })
    commit(types.SET_PRODUCT_LIST, products)
    commit(types.SET_PRODUCT_LOADING, false)
  },

  async createProduct ({ commit }, { product }) {
    commit(types.SET_PRODUCT_SAVING, true)
    await ProductService.createProduct(product)
    commit(types.SET_PRODUCT_SAVING, false)
  },

  async updateProduct ({ commit }, { product }) {
    commit(types.SET_PRODUCT_SAVING, true)
    const res = await ProductService.updateProduct(product)
    commit(types.UPDATE_PRODUCT, res)
    commit(types.SET_PRODUCT_SAVING, false)
  },

  async loadSelectedCampaignConsumerOptions ({ commit }, campaign) {
    const res = await CampaignService.loadConsumerOptions(campaign)
    commit(types.SET_CAMPAIGN_CONSUMER_OPTIONS, res)
  }

}

const mutations = {
  [types.SET_PRODUCT_LIST] (state, products) {
    state.products = products
  },

  [types.SET_PRODUCT_LOADING] (state, loading) {
    state.loading = loading
  },

  [types.SET_SELECTED_CAMPAIGN] (state, campaign) {
    state.selectedCampaign = campaign
  },

  [types.UPDATE_PRODUCT] (state, product) {
    const index = state.products.findIndex((e) => e.id === product.id)
    if (~index) {
      if (product.campaign && product.campaign !== state.selectedCampaign.id) {
        state.products.splice(index, 1)
      } else {
        state.products[index] = { ...product }
      }
    }
  },

  [types.SET_PRODUCT_SAVING] (state, loading) {
    state.saving = loading
  },

  [types.SET_CAMPAIGN_CONSUMER_OPTIONS] (state, options) {
    state.consumerOptions = options
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
