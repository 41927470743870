import Vue from 'vue'
import VueRouter from 'vue-router'

import Navigation from '@/views/Navigation'
import Dashboard from '@/views/Dashboard'
import Products from '@/views/Products'
import LandingPage from '@/views/LandingPage'
import Upgrade from '@/views/Upgrade'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    components: {
      tab: Dashboard,
      navigation: Navigation
    },
    props: { navigation: { active: 'DASHBOARD' } }
  },
  {
    path: '/products',
    name: 'products',
    components: {
      tab: Products,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } },
    children: [{
      name: 'products.edit',
      path: ':id',
      components: {
        tab: Products,
        navigation: Navigation
      },
      props: { navigation: { active: 'PRODUCTS' } }
    }]
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    components: {
      tab: LandingPage,
      navigation: Navigation
    },
    props: { navigation: { active: 'LANDING-PAGE' } }
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    components: {
      tab: Upgrade,
      navigation: Navigation
    },
    props: { navigation: { active: 'UPGRADE' } }
  }
]

const router = new VueRouter({
  routes
})

export default router
