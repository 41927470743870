<template>
  <v-app>
    <router-view name="navigation"></router-view>
    <v-main>
      <div class="main-ctn">
        <router-multi-view class="wrapper" view-name="tab"></router-multi-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'
import amplitude from 'amplitude-js'
import Api from '@/api'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['campaigns', 'currentPlan'])
  },
  components: {
  },
  watch: {
    $route (to, from) {
      console.log('calling appcue epac')
      if (window.Appcues) {
        window.Appcues.page()
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedCampaign: 'SET_SELECTED_CAMPAIGN'
    }),

    initAppCues (user) {
      if (window.Appcues) {
        const signupDate = new Date(user.date_joined)
        window.Appcues.identify(user.id,
          {
            createdAt: signupDate.getTime(), // Unix timestamp of user signup date
            planTier: this.currentPlan.id, // Current user’s plan tier
            role: user.role, // Current user’s role or permissions
            // accountId: "1234", // Current user's account ID
            firstName: user.first_name, // current user's first name
            companyName: user.company.name, // Current user’s company name
            email: user.email, // Current user's email
            language: user.language // for multi-language applications
          }
        )
      }
    },

    initAmplitude (user) {
      const identity = new amplitude.Identify().set('plan', this.currentPlan.id).set('email', user.email)
      amplitude.getInstance().setUserId(user.id)
      amplitude.getInstance().identify(identity)
    }
  },
  async created () {
    if (Api.amplitude_key) {
      amplitude.getInstance().init(Api.amplitude_key)
    }
    if (this.$route.query.token) {
      this.$store.dispatch('setScantrustAuthToken', this.$route.query.token)
      const user = await this.$store.dispatch('loadUserProfile')
      this.initAppCues(user)
      this.initAmplitude(user)
      await this.$store.dispatch('loadCampaigns')
      this.setSelectedCampaign(this.campaigns[0])
    }
  }
}
</script>

<style lang="sass">

@import '@/variables.sass'

main
  background: $light-bg

.main-ctn
  margin-left: auto
  margin-right: auto
  margin-top: 63px
  height: calc(100% - 63px)
  width: 100%
  background: $light-bg

.wrapper
  height: 100%
  position: relative

</style>
