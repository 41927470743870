<template>
  <div class="tooltip" @mouseover="active = true" @mouseleave="active = false">
    <v-icon color="#ccc200">mdi-information</v-icon>
    <div v-show="active" :class="this.position" class="tooltip-ctn pa-4">
      <span ref="tooltip-content" v-html="content"></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoTooltip',
  props: ['content', 'position'],
  data () {
    return {
      active: false
    }
  }
}

</script>

<style lang="sass">

.tooltip
  display: inline-block
  position: relative
  width: 30px

.tooltip-ctn
  font-family: 'Roboto'
  font-size: 13px
  left: 30px
  bottom: 5px
  &.right
    left: 30px
    top: 0px
    bottom: auto
  &.top
    right: 0px
    left: auto
    bottom: 30px

  position: absolute
  background: white !important
  color: #0789b2 !important
  opacity: 1 !important
  width: 250px
  border-radius: 8px
  box-shadow: 0 8px 56px 0 rgba(0, 0, 0, 0.35)
  z-index: 99
  a
    font-weight: 500
    color: #0789b2 !important

</style>
