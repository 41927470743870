<template>
  <v-row align-content="center" justify="center">
    <v-col cols="12" lg="11" xl="8" class="upgrade-wrapper">
      <div class="title d-flex justify-center pa-5">
        <v-icon class="mr-4">$upgrade</v-icon>
        Upgrade Your Plan
      </div>
      <v-row class="card-row flex-nowrap">
        <div class="white-space flex-grow-0 flex-shrink-0"></div>
        <v-col class="plan-card flex-grow-0 flex-shrink-0">
          <div class="plan-title font-weight-bold">ePac Connect</div>
          <div class="price font-weight-light">
            $0
            <div class="billing-hint">Free</div>
          </div>
          <div class="plan-action-ctn">
            <v-btn v-if="currentPlan.id === 'epac-connect'" text>current plan</v-btn>
            <!-- <v-btn class="raised">current plan</v-btn> -->
            <!-- <v-btn class="raised">current plan</v-btn> -->
          </div>
        </v-col>

        <v-col class="plan-card flex-grow-0 flex-shrink-0">
          <div class="plan-title font-weight-bold">ePac Connect Plus</div>
          <div class="price font-weight-light">
             <!-- $ 200
            <div class="billing-hint">Per month<br>Billed annually</div> -->
          </div>
          <div class="plan-action-ctn">
            <!-- <div class="font-weight-bold">CURRENT PLAN</div> -->
            <v-btn v-if="currentPlan.id === 'epac-connect-plus'" text>current plan</v-btn>
            <v-btn v-if="currentPlan.id === 'epac-connect'" color="#5ad2f8" @click="openContactModal" class="raised">get a quote</v-btn>
            <!-- <v-btn class="raised">current plan</v-btn> -->
          </div>
        </v-col>

        <v-col class="plan-card flex-grow-0 flex-shrink-0">
          <div class="plan-title font-weight-bold">ePac Connect Premium</div>
          <div class="price font-weight-light">
          </div>
          <div class="plan-action-ctn">
            <!-- <div class="font-weight-bold">CURRENT PLAN</div> -->
            <v-btn v-if="currentPlan.id === 'epac-connect-premium'" text>current plan</v-btn>
            <v-btn v-if="currentPlan.id === 'epac-connect' || currentPlan.id === 'epac-connect-plus'" @click="openContactModal" color="#5ad2f8" class="raised">get a quote</v-btn>
            <!-- <v-btn class="raised">current plan</v-btn> -->
          </div>
        </v-col>

        <v-col class="plan-card flex-grow-0 flex-shrink-0">
          <div class="plan-title font-weight-bold">Scantrust Enterprise</div>
          <div class="price">
            <v-img :src="require('../assets/st-logo-mini.svg')" max-width="70px"></v-img>
            <div class="billing-hint">for a quotation</div>
          </div>
          <div class="billing-hint"></div>
          <div class="plan-action-ctn">
            <!-- <div class="font-weight-bold">CURRENT PLAN</div> -->
            <v-btn color="#5ad2f8" @click="openContactModal" class="raised">get a quote</v-btn>
            <!-- <v-btn class="raised">current plan</v-btn> -->
          </div>
        </v-col>
      </v-row>

      <div class="plan-section" v-for="(section, $index) in plans.sections" :key="`${section.name}-${$index}`">
        <div class="section-title font-weight-bold">{{section.name}}</div>
        <v-row  align-content="center" class="section-item ma-0 flex-nowrap" v-for="(item, $index) in section.features" :key="`${section.name}-${item.name}-${$index}`">
          <v-col class="flex-shrink-0 flex-grow-0 item-title">
            {{item.name}}
            <info-tooltip v-if="item.tooltip" :content="item.tooltip"></info-tooltip>
          </v-col>
          <v-col class="availability-ctn flex-grow-0 d-flex align-content-center justify-center" v-for="(availability, $index) in item.availability" :key="`${section.name}-${item.name}-availability-${$index}`">
            <v-icon v-if="availability === 'mdi-check' || availability === 'mdi-minus'">{{availability}}</v-icon>
            <span v-if="availability !== 'mdi-check' && availability !== 'mdi-minus'">{{availability}}</span>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-dialog v-model="contactModal" width="600">
      <v-card>
        <v-card-title>Contact Sales now to upgrade</v-card-title>
        <v-card-text>
          <a class="contact-mail" href="mailto:peter.kostur@scantrust.com">peter.kostur@scantrust.com</a><br>
          <a class="contact-mail" href="mailto:rkiley@epacflexibles.com">rkiley@epacflexibles.com</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="contactModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import Plans from '@/const/plans'
import { mapGetters } from 'vuex'
import InfoTooltip from '@/components/utils/InfoTooltip.vue'

export default {
  components: { InfoTooltip },
  name: 'Upgrade',
  computed: {
    ...mapGetters(['currentPlan'])
  },
  data () {
    return {
      plans: Plans,
      contactModal: false
    }
  },
  methods: {
    openContactModal () {
      this.contactModal = true
    }
  }
}

</script>

<style lang="sass">

@import '@/variables.sass'

.upgrade-wrapper
  overflow: auto

.white-space
  width: 293px

.contact-mail
  font-weight: bold
  font-size: 15px

.card-row
  // width: 1170px
  margin-left: auto !important
  margin-right: auto !important

.plan-card
  margin-right: 40px
  min-width: 200px
  position: relative
  height: 300px
  &:nth-child(2)
    background: rgba($vivid-blue, 0.8)
  &:nth-child(3)
    background: rgba($green, 0.8)
  &:nth-child(4)
    background: rgba($orange, 1)
  &:nth-child(5)
    background: rgba($dark-grey, 1)

  .plan-title
    color: white
    font-size: 20px
    text-align: center
    margin-right: auto
    margin-left: auto

  .price
    .v-image
      margin-left: auto
      margin-right: auto
    font-size: 27px
    text-align: center
    color: white
    position: absolute
    top: 120px
    left: 0
    right: 0

  .billing-hint
    margin-top: 20px
    font-size: 13px

  .plan-action-ctn
    position: absolute
    bottom: 10px
    left: 10px
    right: 10px
    color: $black
    text-align: center

.plan-section
  margin-left: auto
  margin-right: auto
  padding: 10px
  padding-right: 0px
  .section-title
    // width: 1170px
    margin-left: auto !important
    margin-right: auto !important
    font-size: 25px
    color: $dark-blue
  .section-item
    margin-left: 0px !important
    margin-right: auto !important
    width: 1203px
    &:nth-child(2)
      border-top: 1px solid #bababa

    border-bottom: 1px solid #bababa
    .item-title
      min-width: 283px
      width: 250px
    .availability-ctn
      text-align: center
      min-width: 200px
      margin-right: 40px
      padding: 10px
      &:nth-child(2)
        background: rgba($vivid-blue, 0.2)
        color: $dark-blue
        .v-icon
         color: $dark-blue
      &:nth-child(3)
        background: rgba($green, 0.2)
        color: $dark-green
        .v-icon
          color: $dark-green
      &:nth-child(4)
        background: rgba($orange, 0.2)
        color: $dark-orange
        .v-icon
          color: $dark-orange
      &:nth-child(5)
        background: rgba($dark-grey, 0.2)
        margin-right: 0px
        color: $dark-grey
        .v-icon
          color: $dark-grey

</style>
