// AUTH
export const SCANTRUST_AUTH_SUCCESS = 'SCANTRUST_AUTH_SUCCESS'
export const SCANTRUST_AUTH_ERROR = 'SCANTRUST_AUTH_ERROR'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const CHANGE_COMPANY_VISION = 'CHANGE_COMPANY_VISION'

// PRODUCTS
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING'
export const SET_SELECTED_CAMPAIGN = 'SET_SELECTED_CAMPAIGN'
export const SET_PRODUCT_SAVING = 'SET_PRODUCT_SAVING'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

// CAMPAIGNS
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST'
export const SET_CAMPAIGN_LOADING = 'SET_CAMPAIGN_LOADING'
export const SET_DASHBOARD_LOAD_STATUS = 'SET_DASHBOARD_LOAD_STATUS'
export const SET_CAMPAIGN_CONSUMER_OPTIONS = 'SET_CAMPAIGN_CONSUMER_OPTIONS'

// DASHBOARD
export const SET_SCANS_LIST = 'SET_SCANS_LIST'
export const SET_DASHBOARD_CAMPAIGN = 'SET_DASHBOARD_CAMPAIGN'
export const SET_SCAN_SUMMARY = 'SET_SCAN_SUMMARY'
export const SET_DASHBOARD_TIMEFRAME = 'SET_DASHBOARD_TIMEFRAME'
export const CLEAR_DASHBOARD_FILTERS = 'CLEAR_DASHBOARD_FILTERS'
export const ADD_DASHBOARD_FILTER = 'ADD_DASHBOARD_FILTER'
export const REMOVE_DASHBOARD_FILTER = 'REMOVE_DASHBOARD_FILTER'
export const UPDATE_DASHBOARD_FILTERS = 'UPDATE_DASHBOARD_FILTERS'

// LANDING PAGES
export const SET_LANDING_PAGE_LIST = 'SET_LANDING_PAGE_LIST'
export const SET_LANDING_PAGE_LOADING = 'SET_LANDING_PAGE_LOADING'
export const UPDATE_PRODUCT_UPDATE_STATUS = 'UPDATE_PRODUCT_UPDATE_STATUS'
